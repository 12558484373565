<template>
  <v-dialog v-model="dialog" fullscreen>
    <v-card
      class="d-flex flex-column"
      style="height: 100%; background: #F2F2F2"
    >
      <div class="flex-none" style="width: 100%">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Xem trước</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </div>
      <div class="flex d-flex pa-3 justify-center" style="overflow-y: auto">
        <div
          v-if="exam"
          class="pa-4 flex-none"
          style="max-width: 1124px; width: 100%"
        >
          <div class="small-title mb-8">
            <div class="d-flex align-center">
              <div>Câu</div>
              <div class="custom-select">
                <v-select
                  class="mx-2"
                  :value="order"
                  :items="orders"
                  outlined
                  hide-details
                  dense
                  style="width: 120px; background: white"
                  @input="backToOrder"
                >
                  <template v-slot:item="{ item }">
                    <div v-if="totalQuestion && item > totalQuestion">Cuối</div>
                    <div v-else>{{ item }}</div>
                  </template>
                </v-select>
              </div>
              <span v-if="totalQuestion">/ {{ totalQuestion }}</span>
            </div>
          </div>

          <v-card class="py-2 mb-6 question" style="overflow: hidden">
            <div class="px-6" style="width: 100%; overflow-y: auto">
              <img :src="exam.content" alt="" />
              <div class="watermark select-none">
                <span v-for="i in 24" :key="i">{{ watermark }}</span>
              </div>
            </div>
            <div class="px-6" style="width: 100%; overflow-x: auto">
              <div v-if="multiSelect">
                <div
                  class="d-flex align-center mb-2"
                  v-for="item in exam.choices"
                  :key="item.order"
                >
                  <v-checkbox
                    class="mt-0"
                    :input-value="item.isCorrect"
                    hide-details
                    readonly
                  >
                  </v-checkbox>

                  <span
                    style="font-weight: bold; margin-right: 10px;margin-top:3px"
                    >{{ alphabet[item.order - 1] }}.</span
                  >
                  <div v-if="item.type === 'text'" style="margin-top:0">
                    {{ item.content }}
                  </div>
                  <div v-else-if="item.type === 'image'" style="margin-top:5px">
                    <img :src="item.image" alt="" />
                  </div>
                </div>
              </div>
              <v-radio-group :value="correctRadio" v-else>
                <div
                  v-for="item in exam.choices"
                  class="d-flex align-center"
                  :key="item.order"
                >
                  <v-radio class="mt-2" :value="item.order" readonly></v-radio>
                  <span style="font-weight: bold; margin-right: 10px"
                    >{{ alphabet[item.order - 1] }}.</span
                  >
                  <div v-if="item.type === 'text'">{{ item.content }}</div>
                  <div v-else-if="item.type === 'image'">
                    <img :src="item.image" alt="" />
                  </div>
                </div>
              </v-radio-group>
            </div>
          </v-card>
          <v-layout align-center>
            <v-btn
              v-if="
                totalQuestion &&
                  exam &&
                  String(totalQuestion) === String(exam.order)
              "
              :loading="loading"
              color="primary"
              @click="submitAnswerSubmit"
            >
              <v-icon left>mdi-check</v-icon>
              Kết thúc
            </v-btn>
            <v-btn
              v-else
              :loading="loading"
              color="secondary"
              @click="submitAnswerSubmit"
            >
              <v-icon left>mdi-chevron-right</v-icon>
              Tiếp theo
            </v-btn>
          </v-layout>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    questions: { type: Array, default: () => [] }
  },

  data: () => ({
    watermark: "20XXYYYY",
    totalQuestion: 0,
    alphabet: ["A", "B", "C", "D", "E", "F", "G", "H"],
    loading: false,
    order: 0
  }),

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },

    exam() {
      if (this.order > 0 && this.questions.length > 0) {
        return this.questions[this.order - 1];
      } else {
        return null;
      }
    },

    orders() {
      return Array.from(Array(this.questions.length).keys()).map(e => e + 1);
    },

    multiSelect() {
      const exam = this.exam;
      if (!exam) return false;
      const correctAnswers = exam.choices.filter(e => e.isCorrect);
      if (correctAnswers.length > 1) {
        return true;
      } else {
        return false;
      }
    },

    correctRadio() {
      const correctAnswer = this.exam.choices.find(e => e.isCorrect);
      if (correctAnswer) {
        return correctAnswer.order;
      } else {
        return null;
      }
    }
  },

  created() {
    if (this.questions.length > 0) {
      this.order = 1;
    }
    this.totalQuestion = this.questions.length;
  },

  methods: {
    submitAnswerSubmit() {
      if (this.order > this.questions.length - 1) {
        this.order = 1;
      } else {
        this.order += 1;
      }
    },

    backToOrder(val) {
      this.order = val;
    }
  }
};
</script>

<style scoped>
.question > * {
  margin-bottom: 10px;
}

.watermark {
  position: absolute;
  width: 200%;
  top: -25%;
  left: -25%;
  font-size: 90px;
  opacity: 0.1;
  font-weight: bold;
  filter: blur(2px);
  z-index: 100;
  pointer-events: none;
  color: #b2b2b2;
  text-align: center;
  transform: rotate(-25deg);
}

.watermark > span {
  display: inline-block;
  margin-right: 40px;
}

.select-none {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
</style>

<style>
.custom-select .v-select__selection {
  font-size: 19px;
  font-weight: bold;
}
</style>
