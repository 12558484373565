<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay>
    <v-card class="d-flex flex-column" style="height: 100%">
      <div class="flex-none">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Nhập đề tự động</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </div>

      <div class="flex d-flex pl-2 pr-3 pt-3 pb-2">
        <div class="d-flex flex-none pr-2 flex-column" style="width: 300px; overflow-y: auto">
          <v-card class="ml-1 pa-3 flex-none" :loading="loading">
            <v-file-input
              @change="uploadFile"
              accept="application/pdf"
              label="Chọn đề"
              :disabled="loading"
            ></v-file-input>
            <div class="c-title">Chế độ:</div>
            <v-radio-group v-model="drawMode">
              <v-radio
                label="Vẽ câu hỏi (Q)"
                value="draw_question"
                color="purple"
              ></v-radio>
              <v-radio
                label="Vẽ câu trả lời (A)"
                value="draw_answer"
                color="blue"
              ></v-radio>
            </v-radio-group>
            <div class="c-title">Tùy chỉnh:</div>
            <v-checkbox
              v-model="deletable"
              label="Hiển thị nút xóa (D)"
              dense
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="showMetadata"
              label="Hiển thị thuộc tính (M)"
              dense
            ></v-checkbox>
            <v-btn class="mb-2" style="width: 100%" @click="fillAnswers" dense
              >Đáp án đầu tiên là đúng</v-btn
            >
            <v-checkbox
              v-model="saveOverwrite"
              label="Xóa câu hỏi cũ trước khi lưu"
              dense
            ></v-checkbox>
            <v-btn
              color="secondary"
              style="width: 100%"
              @click="cropImage"
              :disabled="loading"
              >Lưu</v-btn
            >
          </v-card>
          <v-card class="flex ml-1 mt-3 pa-3 mb-1" style="min-height: 180px">
            <div class="c-title">Thuộc tính:</div>
            <template v-if="selectedDraw">
              <v-text-field
                :value="selectedDraw.questionOrder"
                label="Thứ tự câu hỏi"
                type="number"
                min="1"
                hide-details
                @input="changeObjectOrder"
              ></v-text-field>
              <template v-if="selectedDraw.drawMode == 'draw_question'">
                <v-text-field
                  :value="selectedDraw.questionLevel"
                  label="Mức độ"
                  min="1"
                  type="number"
                  @input="changeObjectLevel"
                ></v-text-field>
              </template>
              <template v-if="selectedDraw.drawMode == 'draw_answer'">
                <v-switch
                  :inputValue="selectedDraw.correctAnswer"
                  label="Đáp án đúng"
                  @change="changeObjectCorrection"
                ></v-switch>
              </template>
            </template>
          </v-card>
        </div>
        <div class="flex pl-1 pb-1">
          <v-card style="width: 100%; height: 100%; overflow-y: auto;">
            <div
              ref="pdfContainer"
              style="width: calc(100% - 10px); height: 100%"
            >
              <ExamEditor
                ref="examEditors"
                v-for="(image, idx) in images"
                :key="idx"
                :image="image"
                :containerWidth="containerWidth"
                :drawMode="drawMode"
                :deletable="deletable"
                :showMetadata="showMetadata"
                :currentOrder="currentOrder"
                @currentChange="currentChange($event, idx)"
                @pointDown="setQuestionOrder($event, idx)"
              ></ExamEditor>
            </div>
          </v-card>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import ExamEditor from "./components/ExamEditor.vue";

export default {
  components: {
    ExamEditor
  },

  props: {
    value: { type: Boolean, default: false }
  },

  data: () => ({
    file: null,
    drawMode: "draw_question",
    deletable: false,
    showMetadata: true,
    currentObj: null,
    mouseOver: false,
    scale: 1,
    loading: false,
    pages: [],
    images: [],
    containerWidth: 0,
    currentOrder: 0,
    saveOverwrite: true
  }),

  computed: {
    dialog: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      }
    },

    selectedDraw() {
      if (this.currentObj != null) {
        return {
          drawMode: this.currentObj.drawMode || null,
          questionOrder: this.currentObj.questionOrder,
          correctAnswer: this.currentObj.correctAnswer,
          questionLevel: this.currentObj.questionLevel
        };
      } else {
        return null;
      }
    }
  },

  mounted() {
    this.containerWidth = this.$refs.pdfContainer.clientWidth;
    this.initShortcuts();
  },

  destroyed() {
    this.removeShortcuts();
  },

  methods: {
    async getImageFromData(data) {
      return new Promise((resolve, reject) => {
        try {
          const img = new Image();
          img.onload = () => {
            resolve(img);
          };
          img.src = data;
        } catch (e) {
          reject(null);
        }
      });
    },

    async uploadFile(file) {
      if (!file) return;

      this.loading = true;
      this.images = [];
      this.$nextTick(async () => {
        try {
          const form = new FormData();
          form.append("file", file);
          let url = localStorage.getItem('_pdf_url_') || "/pdf/exam/extract"
          const exam = await axios({
            method: "post",
            url,
            data: form,
            headers: { "Content-Type": "multipart/form-data" }
          });
          this.images = exam.data.image;
          this.pages = exam.data.page;
          this.$nextTick(async () => {
            for (let i = 0; i < this.images.length; i++) {
              const canvas = this.$refs.examEditors[i];
              await canvas.init();
            }

            const questions = this.pages.questions.sort(
              (a, b) => a.bbox[1] - b.bbox[1]
            );

            for (const qt of questions) {
              this.$refs.examEditors[qt.page].addQuestion(
                qt.order,
                qt.level,
                qt.bbox
              );
            }

            for (const aws of this.pages.answers) {
              const qsAbove = questions.filter(
                e => e.page === aws.page && e.bbox[1] < aws.bbox[1]
              );
              if (qsAbove.length > 0) {
                this.$refs.examEditors[aws.page].addAnswer(
                  qsAbove[qsAbove.length - 1].order,
                  false,
                  aws.bbox
                );
              } else if (aws.page > 0) {
                const lastQs = questions.filter(e => e.page === aws.page - 1);
                let order = 1;
                if (lastQs.length > 0) {
                  order = lastQs[lastQs.length - 1].order;
                }
                this.$refs.examEditors[aws.page].addAnswer(
                  order,
                  false,
                  aws.bbox
                );
              } else {
                this.$refs.examEditors[aws.page].addAnswer(1, false, aws.bbox);
              }
            }
            // canvas.renderAll();
          });
        } finally {
          this.loading = false;
        }
      });
    },

    // Shortcuts
    initShortcuts() {
      this.shortCutsFuncUp = e => {
        switch (e.key) {
          case "q":
            this.drawMode = "draw_question";
            break;

          case "a":
            this.drawMode = "draw_answer";
            break;

          case "d":
            this.deletable = !this.deletable;
            break;

          case "m":
            this.showMetadata = !this.showMetadata;
            break;

          case "Delete":
            this.deleteCurrentObject();
            break;

          default:
            break;
        }
      };

      this.shortCutsFuncDown = e => {
        switch (e.key) {
          case "ArrowUp":
            this.moveSelection("up");
            break;

          case "ArrowDown":
            this.moveSelection("down");
            break;

          case "ArrowLeft":
            this.moveSelection("left");
            break;

          case "ArrowRight":
            this.moveSelection("right");
            break;

          default:
            break;
        }
      };

      document.addEventListener("keyup", this.shortCutsFuncUp);
      document.addEventListener("keydown", this.shortCutsFuncDown);
    },

    removeShortcuts() {
      document.removeEventListener("keyup", this.shortCutsFuncUp);
      this.shortCutsFuncUp = undefined;

      document.removeEventListener("keydown", this.shortCutsFuncDown);
      this.shortCutsFuncDown = undefined;
    },

    getActiveObject() {
      for (let exam of this.$refs.examEditors) {
        const obj = exam.pdfCanvas.getActiveObject();
        if (obj != null) {
          return {
            canvas: exam,
            obj: obj
          };
        }
      }
      return null;
    },

    currentChange(obj, idx) {
      for (let i = 0; i < this.$refs.examEditors.length; i++) {
        if (i !== idx) {
          this.$refs.examEditors[i].pdfCanvas.discardActiveObject();
          this.$refs.examEditors[i].renderAll();
        }
      }
      this.currentObj = obj;
    },

    deleteCurrentObject() {
      const exam = this.getActiveObject();
      if (exam != null) {
        exam.canvas.pdfCanvas.remove(exam.obj);
        this.currentObj = null;
      }
    },

    changeObjectOrder(val) {
      const exam = this.getActiveObject();
      const obj = exam.obj;
      if (obj == null || obj.drawMode == null || val === "") return;

      obj["questionOrder"] = Number(val);
      if (obj["questionLevel"]) {
        obj._objects[1].set("text", String(val) + ", m" + obj["questionLevel"]);
      } else {
        obj._objects[1].set("text", String(val));
      }

      exam.canvas.renderAll();
    },

    changeObjectLevel(val) {
      const exam = this.getActiveObject();
      const obj = exam.obj;
      if (obj == null || obj.drawMode == null || val === "") return;

      obj["questionLevel"] = Number(val);
      obj._objects[1].set("text", String(obj["questionOrder"]) + ", m" + val);

      exam.canvas.renderAll();
    },

    changeObjectCorrection(val) {
      const exam = this.getActiveObject();
      const obj = exam.obj;
      if (obj == null || obj.drawMode == null || val === "") return;

      obj["correctAnswer"] = val;

      const color = val ? "green" : "blue";

      obj._objects[0].set("stroke", color);
      obj._objects[1].set("fill", color);
      exam.canvas.renderAll();
    },

    fillAnswers() {
      const needSetCorrect = [];
      for (let exam of this.$refs.examEditors) {
        const aws = exam.getAnswerObjs();
        for (let aw of aws) {
          const hasSet = needSetCorrect.find(
            e => e.questionOrder == aw.questionOrder
          );
          if (!hasSet) {
            needSetCorrect.push(aw);
            aw.correctAnswer = true;
            aw._objects[0].set("stroke", "green");
            aw._objects[1].set("fill", "green");
          }
        }
        exam.renderAll();
      }
    },

    setQuestionOrder(ev, idx) {
      const qs = this.$refs.examEditors[idx]
        .getQuestionObjs()
        .filter(e => e.top < ev.y);

      if (qs.length > 0) {
        this.currentOrder = qs[qs.length - 1]["questionOrder"];
      } else if (idx > 0) {
        const qsAbove = this.$refs.examEditors[idx - 1].getQuestionObjs();
        if (qsAbove.length > 0) {
          this.currentOrder = qsAbove[qsAbove.length - 1]["questionOrder"];
        } else {
          this.currentOrder = 1;
        }
      } else {
        this.currentOrder = 1;
      }
    },

    setScale(scale) {
      this.scale = scale;
      this.$refs.examEditors.forEach(e => {
        e.pdfCanvas.setZoom(this.scale);
        e.renderAll();
      });
    },

    hideAllObjs() {
      this.$refs.examEditors.forEach(k => {
        const objs = k.pdfCanvas.getObjects();
        objs.forEach(e => {
          if (e.drawMode != null) {
            e.visible = false;
          }
        });
      });
    },

    cropPart(exam, e) {
      const position = {
        top: e.top * this.scale,
        left: e.left * this.scale,
        width: e.width * this.scale * e.scaleX,
        height: e.height * this.scale * e.scaleY
      };
      return exam.pdfCanvas.toDataURL({
        format: "jpeg",
        ...position
      });
    },

    async mergeQuestionImage(img1, img2) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const image1 = await this.getImageFromData(img1);
      const image2 = await this.getImageFromData(img2);
      canvas.width = Math.max(image1.width, image2.width);
      canvas.height = image1.height + image2.height;
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image1, 0, 0);
      context.drawImage(image2, 0, image1.height);
      return canvas.toDataURL("image/jpeg");
    },

    async cropImage() {
      try {
        this.loading = true;
        const objs0 = this.$refs.examEditors[0].pdfCanvas.getObjects();
        const img0 = objs0.find(e => e.isExam);
        this.hideAllObjs();
        this.setScale(1024 / img0.width);

        const questions = [];
        for (const exam of this.$refs.examEditors) {
          const objs = exam.pdfCanvas
            .getObjects()
            .sort((a, b) => a.top - b.top);
          for (const e of objs) {
            if (e.drawMode === "draw_question") {
              const dubOrderQs = questions.find(
                k => k.order === e.questionOrder
              );
              if (dubOrderQs) {
                dubOrderQs.image = await this.mergeQuestionImage(
                  dubOrderQs.image,
                  this.cropPart(exam, e)
                );
              } else {
                questions.push({
                  image: this.cropPart(exam, e),
                  order: e.questionOrder,
                  level: e.questionLevel,
                  answers: []
                });
              }
            }
          }
        }
        this.$refs.examEditors.forEach(exam => {
          const objs = exam.pdfCanvas.getObjects();
          objs.forEach(e => {
            if (e.drawMode === "draw_answer") {
              const image = this.cropPart(exam, e);
              const question = questions.find(f => f.order === e.questionOrder);
              if (question) {
                question.answers.push({
                  image,
                  isCorrect: e.correctAnswer,
                  order: question.answers.length + 1
                });
              }
            }
          });
        });
        this.$emit("crop", {
          saveOverwrite: this.saveOverwrite,
          questions
        });
        this.dialog = false;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.pdf-container {
  width: 100%;
}

.c-title {
  font-weight: bold;
}
</style>