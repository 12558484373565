<template>
  <div>
    <canvas ref="pdfCanvas" class="pdf-container"> </canvas>
  </div>
</template>

<script>
import { fabric } from "fabric";

export default {
  props: {
    image: { type: String, default: null },
    containerWidth: { type: Number, default: 0 },
    drawMode: { type: String, default: null },
    deletable: { type: Boolean, default: false },
    showMetadata: { type: Boolean, default: false },
    currentOrder: { type: Number, default: 0 }
  },
  data: () => ({
    pdfCanvas: null,
    pointDown: null,
    _currentObj: null,
    scale: 1,
    canDraw: true
  }),

  computed: {
    currentObj: {
      get() {
        return this._currentObj;
      },
      set(val) {
        this._currentObj = val;
        this.$emit("currentChange", val);
      }
    }
  },

  watch: {
    deletable() {
      this.renderAll();
    },

    showMetadata() {
      const objs = this.pdfCanvas.getObjects().filter(e => e.drawMode != null);
      objs.forEach(e => {
        e._objects[1].visible = this.showMetadata;
      });
      this.renderAll();
    }
  },

  async mounted() {
    this.pdfCanvas = new fabric.Canvas(this.$refs.pdfCanvas);
    const controls = fabric.Object.prototype.controls;
    const rotateControls = controls.mtr;
    rotateControls.visible = false;
    this.initHandler();
    this.initControls();
  },

  methods: {
    renderAll() {
      this.pdfCanvas.requestRenderAll();
    },

    getFabricImage(img) {
      const fImage = new fabric.Image(img);
      fImage.lockMovementX = true;
      fImage.lockMovementY = true;
      fImage.selectable = false;
      fImage.hasBorders = false;
      fImage.hasControls = false;
      fImage.hoverCursor = "initial";
      fImage.isExam = true;
      return fImage;
    },

    async getImageFromData(data) {
      return new Promise((resolve, reject) => {
        try {
          const img = new Image();
          img.onload = () => {
            resolve(img);
          };
          img.src = data;
        } catch (e) {
          reject(null);
        }
      });
    },

    async init() {
      const jpeg = await this.getImageFromData(this.image);
      const img = this.getFabricImage(jpeg);
      this.scale = this.containerWidth / img.width;
      this.pdfCanvas.setDimensions({
        width: this.containerWidth,
        height: img.height * this.scale
      });

      this.pdfCanvas.setZoom(this.scale);
      this.pdfCanvas.add(img);
    },

    addQuestion(order, level, bbox) {
      const kw = {};
      const strokeColor = "purple";
      kw["questionOrder"] = order;
      kw["questionLevel"] = level;
      const text = String(kw["questionOrder"]) + ", m" + kw["questionLevel"];
      return this.addBox({
        strokeColor,
        bbox,
        text,
        drawMode: "draw_question",
        kw
      });
    },

    addAnswer(order, correct, bbox) {
      const kw = {};
      const strokeColor = correct ? "green" : "blue";
      kw["questionOrder"] = order;
      kw["correctAnswer"] = correct;
      const text = String(kw["questionOrder"]);
      return this.addBox({
        strokeColor,
        bbox,
        text,
        drawMode: "draw_answer",
        kw
      });
    },

    addBox({ strokeColor, bbox, text, drawMode, kw }) {
      const rect = new fabric.Rect({
        fill: null,
        stroke: strokeColor,
        strokeWidth: 2,
        top: 0,
        left: 0,
        width: bbox[2],
        height: bbox[3],
        objectCaching: false,
        strokeUniform: true
      });

      const textObj = new fabric.Text(text, {
        top: 6,
        left: 6,
        fontSize: 14 / this.scale,
        fontWeight: "bold",
        fill: strokeColor,
        strokeUniform: true,
        objectCaching: false,
        backgroundColor: "white",
        visible: this.showMetadata
      });

      const group = new fabric.Group([rect, textObj], {
        drawMode: drawMode,
        ...kw,
        left: bbox[0],
        top: bbox[1],
        objectCaching: false,
        strokeUniform: true
      });

      group.on("scaling", function(e) {
        this._objects[1].set({
          scaleX: 1 / this.scaleX,
          scaleY: 1 / this.scaleY
        });
      });

      group.on("mousedown", e => {
        this.canDraw = false;
        this.currentObj = group;
      });

      group.on("mouseup", e => {
        this.canDraw = true;
      });

      this.pdfCanvas.add(group);
      return group;
    },

    getQuestionObjs() {
      return this.pdfCanvas
        .getObjects()
        .filter(e => e.drawMode == "draw_question")
        .sort((a, b) => a.top - b.top);
    },

    getAnswerObjs() {
      return this.pdfCanvas
        .getObjects()
        .filter(e => e.drawMode == "draw_answer")
    },

    initHandler() {
      const canvas = this.pdfCanvas;
      canvas.on("mouse:down", e => {
        this.pointDown = canvas.getPointer(e.e);
        this.currentObj = null;
        this.$emit("pointDown", this.pointDown);
      });

      canvas.on("mouse:over", e => {
        this.mouseOver = true;
      });

      canvas.on("mouse:out", e => {
        this.mouseOver = false;
      });

      const getSmaller = (a, b) => (a < b ? a : b);

      canvas.on("mouse:up", e => {
        if (!this.canDraw) {
          this.pointDown = null;
        }
        if (!this.pointDown) return;
        const pointer = canvas.getPointer(e.e);
        const pointDown = this.pointDown;
        this.pointDown = null;
        const width = Math.abs(pointer.x - pointDown.x);
        const height = Math.abs(pointer.y - pointDown.y);
        if (width < 5 && height < 5) return;

        const top = getSmaller(pointDown.x, pointer.x);
        const left = getSmaller(pointDown.y, pointer.y);
        const bbox = [top, left, width, height];
        let group = null;

        if (this.drawMode === "draw_question") {
          group = this.addQuestion(this.currentOrder + 1, 1, bbox);
        } else if (this.drawMode === "draw_answer") {
          group = this.addAnswer(this.currentOrder, false, bbox);
        }
        if (group != null) {
          canvas.setActiveObject(group);
          this.currentObj = group;
        }
      });
    },

    async initControls() {
      const deleteIcon =
        "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";

      const img = await this.getImageFromData(deleteIcon);
      const self = this;
      const deleteObject = (eventData, transform) => {
        if (self.deletable) {
          var target = transform.target;
          var canvas = target.canvas;
          canvas.remove(target);
          canvas.requestRenderAll();
          this.currentObj = null;
        }
      };

      async function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        if (self.deletable) {
          var size = this.cornerSize * 0.8;
          ctx.save();
          ctx.translate(left, top);
          ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
          ctx.drawImage(img, -size / 2, -size / 2, size, size);
          ctx.restore();
        }
      }
      fabric.Object.prototype.controls.deleteControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: 16,
        cursorStyle: "pointer",
        mouseUpHandler: deleteObject,
        render: renderIcon,
        cornerSize: 24
      });
    },

    moveSelection(direction) {
      const obj = this.pdfCanvas.getActiveObject();
      if (obj != null) {
        if (direction === "up") {
          obj.set({ top: obj.top - 1 });
        } else if (direction === "down") {
          obj.set({ top: obj.top + 1 });
        } else if (direction === "left") {
          obj.set({ left: obj.left - 1 });
        } else if (direction === "right") {
          obj.set({ left: obj.left + 1 });
        }

        this.renderAll();
      }
    }
  }
};
</script>